import React from "react";
import { Box, Typography, Button } from "@material-ui/core";

import "./index.scss";

const Index = () => {
  return (
    <div className="page-404">
      <Typography component="h1" variant="h1">
        <Box>404</Box>
      </Typography>
      <Typography component="h2" variant="h4">
        <Box>Clearly what you're looking for isn't there :(</Box>
      </Typography>
      <Box my={5}>
        <Button color="secondary" variant="outlined" href="https://minespace.net">Let me out!</Button>
      </Box>
    </div>
  );
};

export default Index;
