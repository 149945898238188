import React from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import "./page.scss";


const Page = (Navbar, Content) => (props) => (
  <>
    <Navbar {...props} />
    <ReactCSSTransitionGroup
      transitionAppear={true}
      transitionLeaveTimeout={600}
      transitionAppearTimeout={600}
      transitionEnterTimeout={600}
      transitionName="fade"
      className="page-body"
    >
      <Content {...props} />
    </ReactCSSTransitionGroup>
  </>
);

export default Page;
