//React-Redux bindings
import { createStore, applyMiddleware, combineReducers} from "redux";

//React-logger middleware
// import { createLogger } from "redux-logger";

//React-thunk middleware
import thunkMiddleware from "redux-thunk";

//Reducers
import { ServersInfo } from "../reducers/Global/serverInfo.reducer.js";
import { ServerMap } from "../reducers/Global/serverMap.reducer.js";
import { CategoryList } from "../reducers/Speedrun/categoryList.reducer.js";
import { StyleList } from "../reducers/Speedrun/styleList.reducer.js";
import { PlayerTimes } from "../reducers/Speedrun/playerTimes.reducer.js";
import { NerdsList } from "../reducers/Speedrun/nerdsList.reducer.js";
import { Top15List } from "../reducers/Speedrun/top15List.reducer.js";
import { RankingList } from "../reducers/Arena/playerRanking.reducer.js";
import { ThemeToggler } from "../reducers/Global/toggleTheme.reducer.js";
// import {
//   LogsTree,
//   LogsTreeLocation,
//   LogsSearchField,
// } from "../reducers/Speedrun/logs.reducer.js";
import { DemoList } from "../reducers/Speedrun/demoList.reducer.js";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const logger = createLogger();


const rootReducer = combineReducers({
  ThemeToggler,
  ServersInfo,
  ServerMap,
  CategoryList,
  StyleList,
  PlayerTimes,
  Top15List,
  NerdsList,
  DemoList,
  RankingList,
  // LogsTree,
  // LogsTreeLocation,
  // LogsSearchField,
});

export const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware)
);
