import * as types from "../../types/Global/serverInfo.types.js";

export const requestServerInfo = () => (dispatch) => {
	dispatch({ type: types.REQUEST_SERVER_INFO_PENDING });
	fetch(`https://minespace.net/api/public/serverlist`, {
		method: "GET",
	})
		.then((res) => res.json())
		.then((data) => {
			dispatch({
				type: types.REQUEST_SERVER_INFO_SUCCESS,
				payload: data,
			})
		})
		.catch((err) =>
			dispatch({ type: types.REQUEST_SERVER_INFO_FAILED, payload: err })
		);
};

