import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const mapStateToProps = (state) => {
  return {
    ranking: state.RankingList.Ranking,
  };
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


const Ranking = ({ ranking }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell>Player</TableCell>
            <TableCell align="center">Kills</TableCell>
            <TableCell align="center">Deaths</TableCell>
            <TableCell align="right">Score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { ranking.length ?
          ranking.map((row, id) => (
            <TableRow key={row.name}>
              <TableCell>{id+1}</TableCell>
              <TableCell component="th" scope="row">
                {row.player.name}
              </TableCell>
              <TableCell align="center">{row.kills}</TableCell>
              <TableCell align="center">{row.deaths}</TableCell>
              <TableCell align="right">{row.score}</TableCell>
            </TableRow>
          )) :
          <TableRow>
            <TableCell colspan="5">No records to display.</TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default connect(mapStateToProps, null)(Ranking);
