import React, { Component } from "react";
import { Container, Grid, Box, Dialog, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ServerItem from "../components/ServerItem/ServerItem";
import { withStyles } from '@material-ui/core/styles';

import './index.scss';

import { connect } from "react-redux";
import { requestServerInfo } from "../redux/actions/Global/serverInfo.action.js";

const serversSelector = (state) => {
	return {
		Servers: [...state.ServersInfo.Servers],
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onRequestServer: () => dispatch(requestServerInfo()),
	};
};


const styles = ({ palette, spacing }) => ({
	layoutContainer: {
		background: palette.background.default,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	},
	grid: {
		height: '100%',
		width: '100% !important'
	},
	tableRow: {
		fontWeight: 100
	}
});


class Index extends Component {
	constructor(props) {
		super(props)

		this.state = {
			dialogTitle: "",
			dialogPlayers: [],
			dialogOpen: false
		}
	}

	componentDidMount() {
		this.props.onRequestServer();
	}

	handleClose = () => this.setState({ dialogOpen: false });
	handleOpen = (hostname, players) => this.setState({
		dialogOpen: true,
		dialogTitle: hostname,
		dialogPlayers: players
	});
	
	render() {
		const { classes, Servers } = this.props;
		const { dialogOpen, dialogTitle, dialogPlayers } = this.state;

		return (
			<div className="main">
				<Container className={classes.layoutContainer} maxWidth="xl">
					<Typography variant="h4" color="textPrimary"><Box m={5}>Minespace</Box></Typography>
					<Grid
						className={classes.grid}
						container
						item
						spacing={5}
						direction="row"
						justify="center"
						alignItems="center"
						xl={10} lg={10} md={10}
					>
						{Servers.map((item, id) => {
							return <ServerItem item={item} key={id} handleOpenDialog={this.handleOpen} />;
						})}

						<Dialog onClose={this.handleClose} aria-labelledby="Player dialog" open={dialogOpen}>
							<DialogTitle id="Player dialog">{dialogTitle}</DialogTitle>
							<TableContainer component={Paper}>
								<Table className={classes.table} size="small" aria-label="a dense table">
									<TableHead>
									<TableRow>
										<TableCell>Player</TableCell>
										<TableCell align="right">Time online</TableCell>
									</TableRow>
									</TableHead>
									<TableBody>
									{dialogPlayers.length
									? dialogPlayers.map((player, key) => (
										<TableRow key={key} className={classes.tableRow}>
											<TableCell component="th" scope="row">{player.Name ? player.Name : <i>unknown</i>}</TableCell>
											<TableCell align="right">{player.TimeF}</TableCell>
										</TableRow>
									))
									: <TableRow>
										<TableCell colspan="2">No players online.</TableCell>
									  </TableRow>
									}
									</TableBody>
								</Table>
							  </TableContainer>
						</Dialog>
					</Grid>
				</Container>
			</div>
		);
	}
}

export default connect(serversSelector, mapDispatchToProps)(withStyles(styles)(Index));

