import React, { Component } from "react";
import { Container, Typography, Box } from '@material-ui/core';

class Rules extends Component {
  render() {
    return (
		<Container>
			<Typography variant="h4" component="h2" align="center">
				<Box my={5}>Rules</Box>
			</Typography>
			<ol>
				<li>No cheats and exploits (unless showing them off to mappers on playtesting servers) or 3rd party tools that give you an unfair advantage over other players.</li>
				<li>Admins reserve the right to ban player in case of ANY suspicious behaviour.</li>
				<li>Spam or advertising will be punished with a temporary gag/mute or ban if serious. If the offense is repeated the player will be given a temporary ban.</li>
				<li>Be respectful to other players: racism, prejudice, political and religious comments aren’t welcome if hostile in nature.</li>
				<li>Do not scream or play music in the voicechat. No useless spamming of the mic. That means no songs/soundbytes, especially if fellow players are complaining.</li>
				<li>Don't stay on the server when AFK (away from keyboard).</li>
				<li>No name/role impersonation of ANYONE, especially staff members/mappers or even YouTubers! We know who is who and in case we can check your identity in 3kliksphilip (admins and moderators have special tags on the scoreboard and chat).</li>
				<li>Please speak English to communicate in the voice chat.</li>
				<li>It is forbidden to use loopholes in our rules. The final decision to ban you or not goes to the admin if he or she thinks your behaviour was disruptive for the server.</li>
				<li>Do not add admins or mods, if you need to tell us something use discord. Only add us in case of URGENT necessity like crashed servers.</li>
				<li>Goading or trolling players into behaving inappropriately is forbidden.</li>
				<li>To appeal a ban, do it through sourcebans. Other ways will be ignored.</li>
				<li>When appealing a ban for cheats/exploits you should include a 'handshow' or any undeniable evidence of being innocent.</li>
				<li>It's forbidden to have a nickname that contains offensive content, cheat websites, invalid names, unsafe or inappropriate content.</li>
			</ol>
		</Container>
    );
  }
}

export default Rules;