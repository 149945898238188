import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Parallax } from "react-parallax";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = ({ palette, spacing }) => ({
  root: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    maxWidth: 345,
  },
  cardContainer: {
    height: "38vh",
    margin: "10px",
  },
  media: {
    height: "15vh",
  },
  container: {
    alignItems: "center",
    minHeight: "50vh",
  },
  parallaxContainer: {
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  insideStyles: {
    background: palette.background.default,
    padding: 20,
    position: "absolute",
  },
  button: {
    color: palette.secondary.main,
  },
});

class Vip extends Component {
  render() {
    const { classes } = this.props;

    const img1 = require(`../../assets/img/speedrun/bg1.jpg`);
    const img4 = require(`../../assets/img/speedrun/bg4.jpg`);
    const img7 = require(`../../assets/img/speedrun/bg7.jpg`);

    const aura = require(`../../assets/img/speedrun/aura.gif`);
    const trail = require(`../../assets/img/speedrun/trail.gif`);

    return (
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item justify="center" alignItems="center">
          <Typography component="div">
            <Box m={2} textAlign="center" fontSize="h3.fontSize">
              Speedrun server VIP
            </Box>
            <Box m={5} textAlign="center" fontSize="h6.fontSize">
              Unique experience of speedrun
            </Box>
          </Typography>
        </Grid>
        <Grid item style={{ height: "50vh", width: "100%" }}>
          <Parallax bgImage={img1} strength={500}>
            <div className={classes.parallaxContainer}>
              <div className={classes.insideStyles}>
                <Typography variant="h5" component="h4">
                  <Box>VIP exclusiveness</Box>
                </Typography>
              </div>
            </div>
          </Parallax>
        </Grid>
        <Grid
          className={classes.container}
          xl={12}
          lg={12}
          container
          item
          direction="column"
          justify="center"
          align="center"
        >
          <Typography component="div">
            <Box m={2} textAlign="center" fontSize="h5.fontSize">
              As a VIP on minespace.net speedrun server, you will have access
              to:
            </Box>
            <Box m={1} textAlign="center">
              Various <b>styles</b> not available for a non-VIP user
            </Box>
            <Box m={1} textAlign="center">
              Exclusive <b>-VIP-</b> tag set before your nickname
            </Box>
            <Box m={1} textAlign="center">
              Custom <b>knife skins</b> menu with extremely detailed, self-made
              skins
            </Box>
            <Box m={1} textAlign="center">
              96 custom <b>aura</b> finishes, unique for community servers
            </Box>
            <Box m={1} textAlign="center">
              <b>Trail</b> menu with various styles of trails
            </Box>
            <Box m={1} textAlign="center">
              Special discord <b>donator status</b> & some additional options
            </Box>
            <Box m={1} textAlign="center">
              And a <b>slot</b> reserved especially for you
            </Box>
          </Typography>
        </Grid>
        <Grid item style={{ height: "50vh", width: "100%" }}>
          <Parallax bgImage={img4} strength={500}>
            <div className={classes.parallaxContainer}>
              <div className={classes.insideStyles}>
                <Typography variant="h5" component="h4">
                  <Box>Special effects</Box>
                </Typography>
              </div>
            </div>
          </Parallax>
        </Grid>
        <Grid
          className={classes.container}
          container
          item
          direction="row"
          justify="space-evenly"
          align="center"
        >
          <Grid item className={classes.cardContainer}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={aura}
                  title="Aura effect"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Aura effect
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Aura is a particle placed below the player and following
                    him. Server provides 96 customly made auras you may choose
                    from.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  size="small"
                  color="secondary"
                  href="steam://connect/89.163.145.87:27075"
                >
                  See in action
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item className={classes.cardContainer}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={trail}
                  title="Player trail"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Player trail
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Our server provides various trail colors supported by custom
                    plugin. You can choose from different types such as
                    'Spectrum' or 'Wave' or just select a solid color one.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  size="small"
                  color="secondary"
                  href="steam://connect/89.163.145.87:27075"
                >
                  See in action
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <Grid item direction="row" style={{ height: "50vh", width: "100%" }}>
          <Parallax bgImage={img7} strength={500}>
            <div className={classes.parallaxContainer}>
              <div className={classes.insideStyles}>
                <Typography variant="h5" component="h4">
                  <Box>Purchase the VIP and support the server!</Box>
                </Typography>
              </div>
            </div>
          </Parallax>
        </Grid>
        <Grid
          className={classes.container}
          container
          xl={10}
          lg={10}
          direction="column"
          align="center"
        >
          <Typography component="div">
            <Box m={2} textAlign="center" fontSize="h4.fontSize">
              How do I buy a VIP?
            </Box>
            <Box m={5} textAlign="center" fontSize="h6.fontSize">
              The only payment method we accept is PayPal. As we develop, we may
              add some more payment methods. For now, please stick to this one
              as it is pretty convienient ;)
            </Box>
            <Box m={2} textAlign="center" fontSize="h5.fontSize">
              If everything is clear for you
            </Box>
            <Box m={5} textAlign="center" fontSize="h6.fontSize">
              The button below will redirect you straight into the website where
              you can order a VIP package.)
            </Box>
          </Typography>

          <Button
            color="secondary"
            variant="outlined"
            style={{ alignSelf: "center", marginTop: "48px" }}
            href="https://store.minespace.net/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Visit the store
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Vip);
