import * as actions from "../../types/Speedrun/styleList.types.js";

const initialStateStyleList = {
  isPending: false,
  error: "",
  StyleList: [],
};

export const StyleList = (state = initialStateStyleList, action = {}) => {
  switch (action.type) {
    case actions.REQUEST_STYLELIST_PENDING:
      return Object.assign({}, state, { isPending: true });
    case actions.REQUEST_STYLELIST_SUCCESS:
      return Object.assign({}, state, {
        isPending: false,
        StyleList: Object.values(action.payload),
      });
    case actions.REQUEST_STYLELIST_FAILED:
      return Object.assign({}, state, {
        isPending: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
