import * as types from "../../types/Speedrun/categoryList.types.js";

export const requestCategoryList = () => (dispatch) => {
  dispatch({ type: types.REQUEST_CATEGORYLIST_PENDING });
  fetch("https://minespace.net/api/public/speedrun/categorylist", {
    method: "GET",
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch({ type: types.REQUEST_CATEGORYLIST_SUCCESS, payload: data });
    })
    .catch((err) =>
      dispatch({ type: types.REQUEST_CATEGORYLIST_FAILED, payload: err })
    );
};
