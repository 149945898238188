import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const mapStateToProps = (state) => {
  return {
    nerds: state.NerdsList.Nerds,
  };
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


function createData(name, duration) {
    let  hour, minute;
    minute = Math.floor(Number(duration) / 60);
    hour = Math.floor(minute / 60);
    minute = minute % 60;
    let time=`${hour} hours ${minute} minutes`
     
  return { name, time };
}



const Nerds = ({ nerds }) => {
    const rows = nerds.map((item, index) => {
        return createData(item.name, item.dur)
    })


     const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell>Player</TableCell>
            <TableCell align="right">Duration</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, id) => (
            <TableRow key={row.name}>
              <TableCell align="centre">{id+1}</TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.time}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default connect(mapStateToProps, null)(Nerds);
