import * as actions from "../../types/Global/toggleTheme.types.js";

let localstorageTheme  = localStorage.getItem('theme');

const initialThemeInfo = {
  isPending: false,
  // error: "",
  theme: localstorageTheme ? localstorageTheme : "light" 
};

export const ThemeToggler = (state = initialThemeInfo, action = {}) => {
  switch (action.type) {
    case actions.REQUEST_TOGGLE_THEME:
      let newTheme = ['light', 'dark'].includes(action.payload) ? action.payload : 'light';
      localStorage.setItem('theme', newTheme);
      return Object.assign({}, state, {
        theme: action.payload,
        isPending: false,
      });
    default:
      return state;
  }
};
