import React, { Component } from "react";
import { Typography, Box } from '@material-ui/core';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './carousel.scss';

import Slider from "react-slick";

const images = [
  { id: 1, title: 'x7', desc: 'Tony | emKay' },
  { id: 2, title: 'legacy', desc: 'Abes Mapper | emKay | Diabel007` | AO' },
  { id: 3, title: 'instinct #2', desc: 'emKay' },
  { id: 4, title: 'blacknwhite #2', desc: 'Sniper | Diabel007`' },
  { id: 5, title: 'blackblue #2', desc: 'Voyajar | emKay' },
  { id: 6, title: 'wonderland #5', desc: 'emKay | Nickelony' },
  { id: 7, title: 'coan', desc: 'cherrie | emKay' },
  { id: 8, title: 'av2009', desc: 'sHell.w | Nickelony' }
];

const bgPath = (id) => { return {'backgroundImage': 'url(' + require(`../../../assets/img/speedrun/bg${id}.jpg`) + ')'}; };
const slides = images.map((img, key) => (
  <div className="background-item" key={key}>
		<div key={img.id} style={bgPath(img.id)}>
			<div className="caption">
				<Typography variant="h4" component="h2"><Box>{img.title}</Box></Typography>
        <Typography variant="h6" component="h3"><Box>{img.desc}</Box></Typography>
			</div>
		</div>
  </div>
));


class Carousel extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 750,
      autoplaySpeed: 4000,
      fade: true    
    };
    return (
      <div className="carousel">
        <Slider {...settings}>
          {slides}
        </Slider>
      </div>
    );
  }
}

export default Carousel;