import React from "react";

import MaterialTable from "material-table"
import "../../assets/material-icons.css";

const RankingTable = () => {
  return (
    <MaterialTable
      title="Records"
      columns={[
        { title: "Name", field: "player.name", col: "playername", filtering: false, sorting: false },
        { title: "Map", field: "map", col: "map" },
        { title: "Style", field: "styleName", col: "style", sorting: false },
        { title: "Time", field: "time", col: "time", filtering: false, render: data => parseFloat(data.time).toFixed(3) },
        { title: "Jumps", field: "jumps", col: "jumps", filtering: false },
        { title: "Strafes", field: "strafes", col: "strafes", filtering: false },
        { title: "Sync", field: "sync", col: "sync", filtering: false },
        { title: "Date", field: "date", col: "date", filtering: false },
      ]}
      data={(query) =>
        new Promise((resolve, reject) => {
          let url = `https://minespace.net/api/public/speedrun/playertimes?page=${
            query.page + 1
          }&rows=${query.pageSize}`;
          for (let item in query.filters) {
            url += `&${query.filters[item].column.col}=${query.filters[item].value}`;
          }
          if (query.orderBy) url += `&order=${query.orderBy.col}`;
          if (query.orderDirection) url += `&dir=${query.orderDirection}`;
          fetch(url)
            .then((response) => response.json())
            .then((result) => {
              resolve({
                data: result.data,
                page: query.page,
                totalCount: result.total,
              });
            });
        })
      }
      options={{
        filtering: true,
        search: false
      }}
      icons={{ Filter: React.Fragment }} // override filter icon
    />
  );
};

export default RankingTable;
