import * as actions from "../../types/Speedrun/nerdsList.types.js";

const initialStateNerdsList = {
  isPending: false,
  error: "",
  Nerds: [],
};

export const NerdsList = (state = initialStateNerdsList, action = {}) => {
  switch (action.type) {
    case actions.REQUEST_NERDSLIST_PENDING:
      return Object.assign({}, state, { isPending: true });
    case actions.REQUEST_NERDSLIST_SUCCESS:
      return Object.assign({}, state, {
        isPending: false,
        Nerds: action.payload,
      });
    case actions.REQUEST_NERDSLIST_FAILED:
      return Object.assign({}, state, {
        isPending: false,
        error: action.payload,
      });
    default:
      return state;
  }
};

