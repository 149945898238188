import * as types from "../../types/Global/serverMap.types.js";

export const requestServerMap = (ip) => (dispatch) => {
  dispatch({ type: types.REQUEST_SERVER_MAP_PENDING });
  fetch(`https://minespace.net/api/public/servermap?ip=${ip}`, {
    method: "GET",
  })
    .then((res) => { return res.json()})
    .then((data) => {
      dispatch({ type: types.REQUEST_SERVER_MAP_SUCCESS, payload: data });
    })
    .catch((err) =>
      dispatch({ type: types.REQUEST_SERVER_MAP_FAILED, payload: err })
    );
};
