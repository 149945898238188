import React, { Component } from "react";
import { connect } from "react-redux";

//Components
import DemoList from "../../components/Speedrun/Demo/DemoList.js";

//Assets
import "../../assets/material-icons.css";

//Actions
import { requestDemoList } from "../../redux/actions/Speedrun/demoList.action.js";

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestDemoList: () => dispatch(requestDemoList()),
  };
};

class SpeedrunTV extends Component {
  componentDidMount() {
    this.props.onRequestDemoList();
  }

  render = () => <DemoList />;
}

export default connect(null, mapDispatchToProps)(SpeedrunTV);
