import * as actions from "../../types/Arena/playerRanking.types.js";

const initialStateRanking = {
  isPending: false,
  error: "",
  Ranking: [],
};

export const RankingList = (state = initialStateRanking, action = {}) => {
  switch (action.type) {
    case actions.REQUEST_RANKING_PENDING:
      return Object.assign({}, state, { isPending: true });
    case actions.REQUEST_RANKING_SUCCESS:
      return Object.assign({}, state, {
        isPending: false,
        Ranking: action.payload,
      });
    case actions.REQUEST_RANKING_FAILED:
      return Object.assign({}, state, {
        isPending: false,
        error: action.payload,
      });
    default:
      return state;
  }
};

