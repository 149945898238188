import * as actions from "../../types/Speedrun/categoryList.types.js";

const initialStateCategoryList = {
  isPending: false,
  error: "",
  CategoryList: [],
};

export const CategoryList = (state = initialStateCategoryList, action = {}) => {
  switch (action.type) {
    case actions.REQUEST_CATEGORYLIST_PENDING:
      return Object.assign({}, state, { isPending: true });
    case actions.REQUEST_CATEGORYLIST_SUCCESS:
      return Object.assign({}, state, {
        isPending: false,
        CategoryList: action.payload,
      });
    case actions.REQUEST_CATEGORYLIST_FAILED:
      return Object.assign({}, state, {
        isPending: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
