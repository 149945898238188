import React, { Component } from "react";
import { connect } from "react-redux";
import { requestServerMap } from "../../redux/actions/Global/serverMap.action.js";
import { requestCategoryList } from "../../redux/actions/Speedrun/categoryList.action.js";
import { requestStyleList } from "../../redux/actions/Speedrun/styleList.action.js";

import Carousel from "../../components/Speedrun/Carousel/Carousel.js";
import Rules from "../../components/Speedrun/Rules.js";
import Styles from "../../components/Speedrun/Styles.js";
import WhatIsSpeedrun from "../../components/Speedrun/WhatIsSpeedrun.js";

import ConnectSnackbar from "../../components/ConnectSnackbar/ConnectSnackbar.js";

import { Grid, Container } from "@material-ui/core";

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestServerMap: (ip) => dispatch(requestServerMap(ip)),
    onRequestCategoryList: () => dispatch(requestCategoryList()),
    onRequestStyleList: () => dispatch(requestStyleList()),
  };
};

class Index extends Component {
  componentDidMount() {
	this.props.onRequestServerMap("89.163.145.87:27075");
	this.props.onRequestCategoryList();
	this.props.onRequestStyleList();
  }

  render() {
    return (
      <div className="speedrun-container">
        <Carousel />
        <Container maxWidth="xl">
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
              <Rules />
              <Styles />
              <WhatIsSpeedrun />
            </Grid>
          </Grid>
        </Container>
        <ConnectSnackbar />
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(Index);
