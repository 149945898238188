import React from "react";
import { connect } from "react-redux";

import IconButton from "@material-ui/core/IconButton";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

import "./connectSnackbar.scss";

const mapStateToProps = (state) => {
  return {
    serverMap: state.ServerMap.Map,
  };
};

const ConnectSnackbar = ({ serverMap }) => {
  return serverMap.length ? (
    <div className="connect-snackbar">
      <span>{serverMap.map}</span>
      <IconButton size="small" aria-label="Join the server" title="Play"
       onClick={() => (window.location.href = serverMap.link)}>
        <PlayCircleOutlineIcon
         
          className="play-icon"
        />
      </IconButton>
    </div>
  ) : (
    <></>
  );
};

export default connect(mapStateToProps, null)(ConnectSnackbar);
