import React from "react";
import ReactDOM from "react-dom";

//React-Redux bindings
import { Provider } from "react-redux";

import { store } from "./redux/helpers/store.js";

import "./index.scss";
import App from "./containers/App/App.js";

ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
