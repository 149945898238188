import React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  CardActionArea,
  Typography,
  Box,
  Button,
  IconButton
} from "@material-ui/core";

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import { connect } from "react-redux";

import "./serveritem.scss";

// import { withStyles, createStyles } from '@material-ui/styles';

const mapStateToProps = (state) => {
  return {
    theme: state.ThemeToggler.theme,
  };
};

const getFlag = (cc) => require(`../../assets/img/flags/${cc}.png`);

const ServerItem = props => {
  const { item, theme, handleOpenDialog } = props;

  const getCardImage = (id) => {
    try {
      return require(`../../assets/img/card-${id}.jpg`);
    } catch (e) {
      return require(`../../assets/img/card-placeholder${theme === 'dark' ? '-dark' : ''}.jpg`);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
      <Card className="card">
        <div
          className={`seperator ${
            item.status ? "gradient-green" : "gradient-red"
          }`}
        ></div>
        <CardActionArea>
          <CardMedia
            component="img"
            image={getCardImage(item.id)}
            height="200"
            title={item.ip}
          />
          <CardContent className="card-content">
            <Typography component="h2" className="hostname">
              <Box mb={1}>{item.hostname}</Box>
            </Typography>
            <Typography variant="subtitle2" component="p" className="ip">
              Map: <b>{item.map}</b>
            </Typography>
            {item.players ? (
              <Typography variant="subtitle2" component="p" className="ip">
                Players: <b>{item.players.count}</b>
              </Typography>
            ) : (
              ""
            )}
            {item.location ? (
              <Typography variant="subtitle2" component="p">
                Location:{" "}
                <img className="location" alt="location" src={getFlag(item.location)} />
              </Typography>
            ) : (
              ""
            )}

            <Typography
              className="mod-name"
              component="div"
              title={item.mod.fullName}
            >
              <Box>{item.mod.name}</Box>
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardContent className="ip-container">
          <Typography variant="subtitle2" component="p" className="ip">
            <span style={{ userSelect: "none" }}>Server ip: </span>
            <b>{item.ip}</b>
          </Typography>
          <IconButton className="show-players" aria-label="settings">
            <PeopleAltIcon onClick={() => handleOpenDialog(item.hostname, item.players.list)} />
          </IconButton>
        </CardContent>
        <CardActions style={{padding: '16px'}}>
          <Button
            color="secondary"
            size="small"
            variant="outlined"
            href={`steam://connect/${item.ip}`}
          >
            Connect
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default connect(mapStateToProps, null)(ServerItem);
