import * as actions from "../../types/Global/serverInfo.types.js";

const initialStateServersInfo = {
  isPending: false,
  error: "",
  Servers: [],
};

export const ServersInfo = (state = initialStateServersInfo, action = {}) => {
  switch (action.type) {
    case actions.REQUEST_SERVER_INFO_PENDING:
      return Object.assign({}, state, { isPending: true });
    case actions.REQUEST_SERVER_INFO_SUCCESS:
      return Object.assign({}, state, {
        Servers: [...action.payload],
        isPending: false,
      });
    case actions.REQUEST_SERVER_INFO_FAILED:
      return Object.assign({}, state, {
        error: action.payload,
        isPending: false,
      });
    default:
      return state;
  }
};
