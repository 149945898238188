import * as types from "../../types/Speedrun/styleList.types.js";

export const requestStyleList =()=> (dispatch) => {
  dispatch({ type: types.REQUEST_STYLELIST_PENDING });
  fetch("https://minespace.net/api/public/speedrun/stylelist", {
    method: "GET",
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch({ type: types.REQUEST_STYLELIST_SUCCESS, payload: data });
    })
    .catch((err) =>
      dispatch({ type: types.REQUEST_STYLELIST_FAILED, payload: err })
    );
};
