import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import {
	Container,
	Paper,
	Box,
	Typography,
	Chip,
	Button,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import "./Vip.scss";
const StyledTableCell = withStyles((theme) => ({
	// head: {
	//   backgroundColor: theme.palette.common.black,
	//   color: theme.palette.common.white,
	// },
	// body: {
	//   fontSize: 14,
	// },
}))(TableCell);


const images = [
	'default',
	'vip',
	'premium'
];

const items = [
	{name: "Slot reservation", values: [0,1,1]},
	{name: "HP", values: [100,105,105]},
	{name: "Grenades", values: [0, 
		["1x Flash", "1x Smoke"],
		["2x Flash", "1x Smoke", "1x TA grenade"]
	]},
	{name: "Medishot", values: [0,0,1]},
	{name: "Credits multiplier", values: ["x1", "x2", "x3"]},
	{name: "!gloves access", values: [0,1,1]},
	{name: "Special connect and disconnect message", values: [0,1,1]},
	{name: "Unique scoreboard tag", values: [0,1,1]},
	{name: "Hud messages: !vipsay", values: [0,0,1]}
];

const ArenaVip = ({ classes }) => {
	return (
		<Container maxWidth="xl">
			<Grid container direction="column" justify="center" alignItems="center">
				<Typography component="div">
					<Box m={2} textAlign="center" fontSize="h3.fontSize">
						Arena VIP Privileges
					</Box>
					<Box m={5} textAlign="center" fontSize="h6.fontSize">
						Unique experience of challenging
					</Box>
				</Typography>
				<Grid
					item
					container
					direction="row"
					justify="center"
					alignItems="flex-start"
					spacing={2}
				>
					{images.map((imgName, imgKey) => (
						<Box className="itemWrapper" m={5} textAlign="center">
							<Typography variant="h6" component="h5">
								<Box mb={2}>{imgName}</Box>
							</Typography>
							<div className={"img-container image-" + imgName}>
								<img
									className="itemImg"
									alt="Player model"
									src={require(`../../assets/img/1v1/${imgName}.png`)}
								/>
							</div>

							
							<TableContainer component={Paper}>
								<Table aria-label={imgName + '-table'}>
									<TableHead>
										{/* <TableRow>
											<StyledTableCell className={imgName}></StyledTableCell>
											<StyledTableCell className={imgName}></StyledTableCell>
										</TableRow> */}
									</TableHead>
									<TableBody>
										{items.map((item, id) => (
											<TableRow key={id}>
												<StyledTableCell>{item.name}</StyledTableCell>

												<StyledTableCell align="right" key={id}>
													<div className="chipWrapper">
														{Array.isArray(item.values[imgKey]) ?
															item.values[imgKey].map((val, id) => (
															<Chip
																className={"chip chip" + imgName}
																key={id}
																variant="outlined"
																label={val}
															/>
														)) : 
															<Chip
																className={"chip chip" + imgName}
																key={id}
																variant="outlined"
																label={
																	item.values[imgKey] === 0 ? 
																		<CloseIcon className="close-icon" /> :
																	item.values[imgKey] === 1 ? 
																		<CheckIcon className="check-icon" /> :
																	item.values[imgKey]
																}
															/>
														}
													</div>
												</StyledTableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					))}
					
				</Grid>
				<Grid container xl={10} lg={10} direction="column" align="center">
					<Typography component="div">
						<Box m={2} textAlign="center" fontSize="h4.fontSize">
							How do I buy a VIP?
						</Box>
						<Box m={5} textAlign="center" fontSize="h6.fontSize">
							The only payment method we accept is PayPal. As we develop, we may
							add some more payment methods. For now, please stick to this one
							as it is pretty convienient ;)
						</Box>
						<Box m={2} textAlign="center" fontSize="h5.fontSize">
							If everything is clear for you
						</Box>
						<Box m={5} textAlign="center" fontSize="h6.fontSize">
							The button below will redirect you straight into the website where
							you can order a VIP package.)
						</Box>
					</Typography>

					<Button
						color="secondary"
						variant="outlined"
						style={{ alignSelf: "center", marginTop: "48px" }}
						href="https://store.minespace.net/"
						rel="noopener noreferrer"
						target="_blank"
					>
						Visit the store
					</Button>
				</Grid>
			</Grid>
		</Container>
	);
};

export default ArenaVip;
