import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { connect } from "react-redux";

import DemoItem from "./DemoItem";
import "./demoList.scss";

const mapStateToProps = (state) => {
  return {
    demos: state.DemoList.Demos,
  };
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const DemoList = ({ demos }) => {
  // console.log(demos);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {Object.keys(demos).map((item, id) => {
            return <Tab key={id} label={item} {...a11yProps(id)} />;
          })}
        </Tabs>
      </AppBar>

      <Typography variant="h4" color="secondary" align="center">
        <Box m={2}>Speedrun TV</Box>
      </Typography>
      <Typography component="p" align="center">
        <Box mb={3}> demos from past 7 days</Box>
      </Typography>
      {Object.values(demos).map((item, id) => {
        return (
          <TabPanel key={id} value={value} index={id}>
            <DemoItem data={item} key={id} />
          </TabPanel>
        );
      })}
    </div>
  );
};

export default connect(mapStateToProps, null)(DemoList);
