import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";

// Main pages
import Page from "../Page/Page.js";
import Index from "../../pages/Index.js";
import Page404 from "../../pages/404/Index.js";

// Navbars
import RootNavbar from "../../components/Navbar/RootNavbar.js";
import SpeedrunNavbar from "../../components/Navbar/SpeedrunNavbar.js";
import ArenaNavbar from "../../components/Navbar/ArenaNavbar.js";
// tbd: AdminNabbar

// Speedrun
import Speedrun from "../../pages/Speedrun/Index.js";
import Ranking from "../../pages/Speedrun/Ranking.js";
import SpeedrunTV from "../../pages/Speedrun/SpeedrunTV.js";
import Vip from "../../pages/Speedrun/Vip.js";
import ArenaVip from "../../pages/Arena/Vip.js";

// 1v1
import Arena from "../../pages/Arena/Index.js";

// Admin

// import Logs from "../../pages/Speedrun/Admin/Logs.js";

const Routes = () => {
  const location = useLocation();

  return (
    <Switch location={location}>
      <Route exact path="/" component={Page(RootNavbar, Index)} />
      <Route
        path="/speedrun"
        render={({ match: { url } }) => (
          <>
            <Route
              path={`${url}/`}
              component={Page(SpeedrunNavbar, Speedrun)}
              exact
            />
            <Route
              path={`${url}/ranking`}
              component={Page(SpeedrunNavbar, Ranking)}
            />
            <Route path={`${url}/vip`} component={Page(SpeedrunNavbar, Vip)} />
            <Route
              path={`${url}/speedruntv`}
              component={Page(SpeedrunNavbar, SpeedrunTV)}
            />
            {/* <Route path={`${url}/logs`} component={Logs} /> */}
            {/* <Route
                      path="/admin"
                      render={({ match: { url } }) => (
                        <>
                          <Route path={`${url}/`} component={Admin} exact /> 
                          <Route path={`${url}/logs`} component={Logs} />
                        </>
                      )}
                    /> */}
          </>
        )}
      />
      <Route
        path={"/1v1"}
        render={({ match: { url } }) => (
          <>
            <Route
              exact
              path={`${url}/`}
              component={Page(ArenaNavbar, Arena)}
            />
            <Route
              path={`${url}/vip`}
              component={Page(ArenaNavbar, ArenaVip)}
            />
          </>
        )}
      />
      <Route path="*" component={Page(RootNavbar, Page404)} />
    </Switch>
    //   </CSSTransition>
    // </TransitionGroup>
  );
};

export default Routes;
