import React, { Component } from "react";
import { connect } from "react-redux";

import { requestRanking } from "../../redux/actions/Arena/playerRanking.action.js";
import { requestServerMap } from "../../redux/actions/Global/serverMap.action.js";

import { withStyles } from "@material-ui/core/styles";

import "./index.scss";

import Ranking from "../../components/Arena/Ranking.js";
import ConnectSnackbar from "../../components/ConnectSnackbar/ConnectSnackbar.js";

import {
  Container,
  Typography,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Chip,
} from "@material-ui/core";

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestRanking: () => dispatch(requestRanking()),
    onRequestServerMap: (ip) => dispatch(requestServerMap(ip)),
  };
};

const styles = ({ palette, spacing }) => ({
  media: {
    height: "200px",
    maxWidth: "345px",
  },
  root: {
    maxWidth: "345px",
  },
  layoutContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
});

class Index extends Component {
  componentDidMount() {
    this.props.onRequestRanking();
    this.props.onRequestServerMap("89.163.145.87:27035");
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className="image-container">
          <div className="header">
            <Typography variant="h2" component="h1">
              <Box>Challenge Yourself</Box>
              <Typography variant="subtitle1" component="h2">
                <Box>Visit our 1v1 server</Box>
              </Typography>
            </Typography>
          </div>
        </div>
        <Container className={classes.layoutContainer} maxWidth="xl">
          <Typography variant="h4" color="textPrimary">
            <Box m={5}>Player ranking</Box>
          </Typography>
          <Ranking />

          <Typography variant="h4" color="textPrimary">
            <Box m={5}>In game Store</Box>
          </Typography>
          <Grid
            container
            direction="row"
            spacing={5}
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={require("../../assets/img/1v1/knife.png")}
                    title="Custom knife"
                    component="img"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Custom knives
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Use with !knife
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={require("../../assets/img/1v1/skin.png")}
                    title="Custom weapon skin"
                    component="img"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Custom weapon skins
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Use with !ws
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
          <Typography variant="h4" color="textPrimary">
            <Box m={5}>Guns menu</Box>
          </Typography>
          <Grid className='chip-container'container direcion="row" justify='center'spacing={5} alignItems="center">
            <Chip color="secondary" label="Rifle choose" />
            <Chip color="secondary" label="Pistol choose" />
            <Chip label="Pistol rounds" color="secondary" />
            <Chip label="AWP rounds" color="secondary" />
            <Chip label="Scout rounds" color="secondary" />
            <Chip label="SMG rounds" color="secondary" />
            <Chip label="Fortnite rounds" color="secondary" />
            <Chip label="Headshot only rounds" color="secondary" />
            <Chip label="Noscope rounds" color="secondary" />
            <Chip label="Challenges" color="secondary" />
          </Grid>
          <ConnectSnackbar />
        </Container>
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(Index));
