import React, { Component } from 'react';

import { toggleTheme } from "../../redux/actions/Global/toggleTheme.action.js";
import { connect } from "react-redux";


import IconButton from "@material-ui/core/IconButton";

import Sun from "@material-ui/icons/WbSunny";
import Moon from "@material-ui/icons/Brightness3";

const ThemeIcon = (props) => {
  return <>{props.variant === "dark" ? <Sun /> : <Moon />}</>;
};

const mapStateToProps = (state) => {
  return {
    theme: state.ThemeToggler.theme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleTheme: (theme) => dispatch(toggleTheme(theme)),
  };
};

class ThemeToggler extends Component {
  switchTheme = (e = '') => {
    if (e) e.stopPropagation(); 
    let newTheme = this.props.theme === "light" ? "dark" : "light";
    this.props.toggleTheme(newTheme);
  };

  render(){
    return(
      <>
        <IconButton
          color="secondary"
          onClick={(e) => this.switchTheme(e)}
          style={{marginLeft: 'auto'}}
          title={"Switch theme to " + (this.props.theme === "light" ? "dark" : "light")}>
            <ThemeIcon variant={this.props.theme} />
        </IconButton>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeToggler);
