import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  withStyles,
  Grid,
  SwipeableDrawer,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import ThemeToggler from "./ThemeToggler";

const styles = ({ palette, spacing }) => ({
  list: {
    width: 200,
  },
  padding: {
    paddingRight: 30,
    cursor: "pointer",
  },
  sideBarIcon: {
    padding: 0,
    cursor: "pointer",
  },
  logo: {
    width: 32,
    height: 32,
  },
  link: {
    color: "inherit",
    marginLeft: "8px",
    textDecoration: "none",
  },
});

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { drawerActivate: false, drawer: false };
    this.createDrawer = this.createDrawer.bind(this);
    this.destroyDrawer = this.destroyDrawer.bind(this);
  }

  componentDidMount() {
    if (window.innerWidth <= 640) {
      this.setState({ drawerActivate: true });
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 640) {
        this.setState({ drawerActivate: true });
      } else {
        this.setState({ drawerActivate: false });
      }
    });
  }

  //Small Screens
  createDrawer = () => {
    return (
      <>
        <AppBar>
          <Toolbar variant={this.props.variant}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <IconButton
                edge="start"
                color="secondary"
                aria-label="menu"
                onClick={() => {
                  this.setState({ drawer: true });
                }}
              >
                <MenuIcon
                  color="secondary"
                  className={this.props.classes.sideBarIcon}
                />
              </IconButton>
              <Typography color="inherit" variant="subtitle2">
              <NavLink to="/" className={this.props.classes.link}>
                  Minespace
                </NavLink>
              </Typography>
              <Typography color="inherit"></Typography>
            </Grid>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
          open={this.state.drawer}
          onClose={() => {
            this.setState({ drawer: false });
          }}
          onOpen={() => {
            this.setState({ drawer: true });
          }}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={() => {
              this.setState({ drawer: false });
            }}
            onKeyDown={() => {
              this.setState({ drawer: false });
            }}
          >
            <List className={this.props.classes.list}>
              <ListItem key={0} divider>
                <NavLink to="/" className={this.props.classes.link}>
                  Minespace
                </NavLink>
                {this.props.themeSwitcher ? <ThemeToggler /> : ""}
              </ListItem>
              {this.props.children.map((item, id) => {
                return (
                  <ListItem key={id} divider color="black">
                    {item}
                  </ListItem>
                );
              })}
            </List>
          </div>
        </SwipeableDrawer>
      </>
    );
  };

  //Larger Screens
  destroyDrawer = () => {
    return (
      <AppBar>
        <Toolbar variant={this.props.variant}>
          <NavLink to="/">
            <img
              src={require(`../../assets/img/ms_logo_small.png`)}
              alt="Minespace logo"
              className={this.props.classes.logo}
            />
          </NavLink>
          {this.props.children}
          {this.props.themeSwitcher ? <ThemeToggler /> : ""}
        </Toolbar>
      </AppBar>
    );
  };

  render = () =>
    this.state.drawerActivate ? this.createDrawer() : this.destroyDrawer();
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navbar);
