import React, { Component } from "react";
import { connect } from "react-redux";

import Statistics from "../../components/Speedrun/Statistics.js";
import RankingTable from "../../components/Speedrun/RankingTable.js";
// import Typography from "@material-ui/core/Typography";
// import Container from "@material-ui/core/Container";
// import Grid from "@material-ui/core/Grid";

import { requestTop15List } from "../../redux/actions/Speedrun/top15List.action.js";
import { requestNerdsList } from "../../redux/actions/Speedrun/nerdsList.action.js";


const mapDispatchToProps = (dispatch) => {
  return {
    onRequestTop15: () => dispatch(requestTop15List()),
    onRequestNerds: () => dispatch(requestNerdsList()),
  };
};

class Ranking extends Component {
  componentDidMount() {
    this.props.onRequestTop15();
    this.props.onRequestNerds();
  }
  render() {
    return (
      <>
        <RankingTable />
        <Statistics />
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(Ranking);
