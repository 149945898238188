import React from "react";

import { connect } from "react-redux";
import "./App.scss";
import {BrowserRouter as Router } from "react-router-dom";
import Footer from "../../components/Footer/Footer.js";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";

import Routes from "../Routes/Routes.js";

import history from '../../history'

const styles = ({ palette, spacing }) => ({
  app: {
    // position: "unset",
    // height: '100%',
    display: "flex",
    // justifyContent: "space-evently",
    flexDirection: "column",
  },
});

const mapStateToProps = (state) => {
  return {
    theme: state.ThemeToggler.theme,
  };
};

class App extends React.Component {
  render() {
    let theme = createMuiTheme({
      palette: {
        type: this.props.theme,
        primary: {
          main: this.props.theme === "dark" ? "#222" : "#303030",
        },
        secondary: {
          main: "#e35c14", // minespace
        },
      },
    });

    const { classes } = this.props;

    return (
      <div className={("App", classes.app)}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router  history={history}>
            <Routes />
          </Router>

          <Footer />
        </ThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, null)(App)
);
