import * as types from "../../types/Speedrun/demoList.types.js";

export const requestDemoList = () => (dispatch) => {
  dispatch({ type: types.REQUEST_DEMOLIST_PENDING });
  fetch("https://minespace.net/api/public/speedrun/groupeddemos", {
    method: "GET",
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch({ type: types.REQUEST_DEMOLIST_SUCCESS, payload: data });
    })
    .catch((err) =>
      dispatch({ type: types.REQUEST_DEMOLIST_FAILED, payload: err })
    );
};

