import * as types from "../../types/Speedrun/nerdsList.types.js";

export const requestNerdsList =() => (dispatch) => {
  dispatch({ type: types.REQUEST_NERDSLIST_PENDING });
  fetch("https://minespace.net/api/public/speedrun/ranking/nerds", {
    method: "GET",
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch({ type: types.REQUEST_NERDSLIST_SUCCESS, payload: data });
    })
    .catch((err) =>
      dispatch({ type: types.REQUEST_NERDSLIST_FAILED, payload: err })
    );
};


