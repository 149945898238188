import React from "react";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MouseOverPopover from "./MouseOverPopover.js";

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

const css = {
  media: {
    height: "calc(150px + 3vw)",
    width: "auto",
  },
};

const mapStateToProps = (state) => {
  return {
    styleList: state.StyleList.StyleList,
    categoryList: state.CategoryList.CategoryList,
    categoryPending: state.CategoryList.isPending,
    stylePending: state.StyleList.isPending,
    categoryError: state.CategoryList.error,
    styleError: state.StyleList.error,
  };
};

const Styles = ({
  styleList,
  categoryList,
  categoryPending,
  stylePending,
  categoryError,
  styleError,
}) => {

  return categoryList ? (
    <Container>
      <Typography variant="h4" component="h2" align="center">
        <Box my={5}>Styles</Box>
      </Typography>
			<Grid container direction="row" justify="center" align="center" spacing={3}>
      {categoryList.map((i) => {
        let categoryStyles = styleList
          .filter((el) => parseInt(el.category || 0) === i.id - 1)
          .map((el) => el.name)
          .join("<br>");

        return (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={i.id}>
            <Card>
              <CardActionArea>
                <CardMedia
                  style={css.media}
                  image={require(`../../assets/img/speedrun/cat${
                    (i.id % 4) + 1
                  }.jpg`)}
                  title={i.name}
                />
                <CardContent style={{ padding: 20 }}>
                  <MouseOverPopover text={categoryStyles}>
                    {i.name}
                  </MouseOverPopover>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
			</Grid>
    </Container>
  ) : (
    <></>
  );
};

export default connect(mapStateToProps, null)(Styles);
