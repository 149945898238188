import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Navbar from './Navbar';

const styles = () => ({
	link: {
    color: 'inherit',
    marginLeft: '8px',
    textDecoration: 'none'
  }
});

class SpeedrunNavbar extends Component {
  render() {
		const { classes } = this.props;

    return (
        <Navbar position="fixed" variant="dense" color="primary" themeSwitcher>
          <NavLink to="/speedrun" className={classes.link}>
            Speedrun
          </NavLink>
          <NavLink to="/speedrun/ranking" className={classes.link}>
            Ranking
          </NavLink>
          <NavLink to="/speedrun/speedruntv" className={classes.link}>
            SpeedrunTV
          </NavLink>
          <NavLink to="/speedrun/vip" className={classes.link}>
            VIP
          </NavLink>
        </Navbar>
    );
  }
}

export default withStyles(styles)(SpeedrunNavbar);