import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Navbar from "./Navbar";

const styles = ({ palette, spacing }) => ({
  link: {
    color: "inherit",
    marginLeft: "8px",
    textDecoration: "none",
  },
});

class RootNavbar extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Navbar position="fixed" variant="dense" themeSwitcher>
        <NavLink to="/speedrun" className={classes.link}>
          Speedrun
        </NavLink>
        <NavLink to="/1v1" className={classes.link}>
          Arena 1v1
        </NavLink>
        <a rel="noopener noreferrer"target="_blank" className={classes.link} href="https://minespace.net/store/">Store</a>

        <a href="https://minespace.net/sourcebans/"rel="noopener noreferrer" target="_blank" className={classes.link}>
          Bans
        </a>
        <a href="https://discord.com/invite/yyfdAgZ"rel="noopener noreferrer" target="_blank" className={classes.link}>
          Discord
        </a>
      </Navbar>
    );
  }
}

export default withStyles(styles)(RootNavbar);
