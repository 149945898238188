import * as actions from "../../types/Speedrun/top15List.types.js";

const initialStateTop15List = {
  isPending: false,
  error: "",
  Top15: [],
};

export const Top15List = (state = initialStateTop15List, action = {}) => {
  switch (action.type) {
    case actions.REQUEST_TOP15LIST_PENDING:
      return Object.assign({}, state, { isPending: true });
    case actions.REQUEST_TOP15LIST_SUCCESS:
      return Object.assign({}, state, {
        isPending: false,
        Top15: action.payload,
      });
    case actions.REQUEST_TOP15LIST_FAILED:
      return Object.assign({}, state, {
        isPending: false,
        error: action.payload,
      });
    default:
      return state;
  }
};

