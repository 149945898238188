import * as types from "../../types/Arena/playerRanking.types.js";

export const requestRanking = () => (dispatch) => {
  dispatch({ type: types.REQUEST_RANKING_PENDING });
  fetch("https://minespace.net/api/public/arena/ranking", {
    method: "GET",
  })
    .then((res) => res.json())
    .then((data) => {

      dispatch({ type: types.REQUEST_RANKING_SUCCESS, payload: data });
    })
    .catch((err) =>
      dispatch({ type: types.REQUEST_RANKING_FAILED, payload: err })
    );
};

