import * as actions from "../../types/Speedrun/playerTimes.types.js";

const initialStatePlayerTimes = {
  isPending: false,
  error: "",
  PlayerTimes: [],
};

export const PlayerTimes = (state = initialStatePlayerTimes, action = {}) => {
  switch (action.type) {
    case actions.REQUEST_PLAYERTIMES_PENDING:
      return Object.assign({}, state, { isPending: true });
    case actions.REQUEST_PLAYERTIMES_SUCCESS:
      return Object.assign({}, state, {
        isPending: false,
        PlayerTimes: action.payload,
      });
    case actions.REQUEST_PLAYERTIMES_FAILED:
      return Object.assign({}, state, {
        isPending: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
