import * as actions from "../../types/Speedrun/demoList.types.js";

const initialStateDemoList = {
  isPending: false,
  error: "",
  Demos: [],
};

export const DemoList = (state = initialStateDemoList, action = {}) => {
  switch (action.type) {
    case actions.REQUEST_DEMOLIST_PENDING:
      return Object.assign({}, state, { isPending: true });
    case actions.REQUEST_DEMOLIST_SUCCESS:
      return Object.assign({}, state, {
        isPending: false,
        Demos: action.payload,
      });
    case actions.REQUEST_DEMOLIST_FAILED:
      return Object.assign({}, state, {
        isPending: false,
        error: action.payload,
      });
    default:
      return state;
  }
};

