import * as actions from "../../types/Global/serverMap.types.js";

const initialStateServerMap = {
  isPending: false,
  error: "",
  Map: [],
};

export const ServerMap = (state = initialStateServerMap, action = {}) => {
  switch (action.type) {
    case actions.REQUEST_SERVER_MAP_PENDING:
      return Object.assign({}, state, { isPending: true });
    case actions.REQUEST_SERVER_MAP_SUCCESS:
      return Object.assign({}, state, {
        isPending: false,
        Map: action.payload,
      });
    case actions.REQUEST_SERVER_MAP_FAILED:
      return Object.assign({}, state, {
        isPending: false,
        error: action.payload,
      });
    default:
      return state;
  }
};
