import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord} from '@fortawesome/free-brands-svg-icons'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { withStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton';

import "./footer.scss";

const styles = ({ palette, spacing }) => ({
  footer: {
    background: palette.primary.main
	},
});


const Footer = ({classes}) => {
  return (
    <div className={"footer-container " + classes.footer}>
      <Grid container direction="column" justify='space-between' alignItems="center">
        <Grid item container direction="row" justify="center">
					<IconButton size="small" title="Join the discord server" href="https://discord.gg/yyfdAgZ">
          	<FontAwesomeIcon className="icon" size="lg" icon={faDiscord} />
					</IconButton>
					<IconButton size="small" title="Visit the store" href="https://minespace.net/store/">
          	<FontAwesomeIcon className="icon" size="lg" icon={faShoppingCart} />
					</IconButton>
        </Grid>
        <Grid item>
          <Typography component="p" className="rights"><Box mt={1}>All rights reserved by Minespace</Box></Typography>
					<Typography component="p" className="signature">
            <Box>
              Website proudly developed by
              <a rel="noopener noreferrer"target="_blank" href="https://steamcommunity.com/id/rsymmot/">Tommy</a>
              and
              <a target="_blank"rel="noopener noreferrer" href="https://steamcommunity.com/id/sasiadofc/">emKay</a>
            </Box>
					</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Footer);
