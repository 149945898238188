import React, { Component } from "react";
import { Container, Typography, Box } from '@material-ui/core';

class WhatIsSpeedrun extends Component {
  render() {
    return (
		<Container>
			<Typography variant="h4" component="h2" align="center">
				<Box my={5}>What is speedrun?</Box>
			</Typography>
			<Typography variant="h6" component="h3">
				<Box my={3}>Origin</Box>
			</Typography>
			<Typography variant="subtitle2">
				Speedrun - name comes from combining 2 english words - speed and run. It's a modification created for racing,
				which is the main goal for this modification. In speedrun the most important thing is achieving good run time
				of completing the map.
				Names of maps which are created especially for speedrun begin with 'speedrun_' prefix. Some players upload
				their gameplay on youtube.
			</Typography>
			<Typography variant="h6" component="h3">
				<Box my={3}>Where does it come from?</Box>
			</Typography>
			<Typography variant="subtitle2">
				Speedrun which was designed - as it was said - to compete. It began on public server web 'cs-enemy', where
				admins soQl and hiroshima initiated speedrun. The first map was released, titled from server name,
				speedrun_enemy. There was also a clan called 'Polish BH Gaming' which was leaded by soQl.
			</Typography>
			<Typography variant="h6" component="h3">
				<Box my={3}>Strafes and gaining speed.</Box>
			</Typography>
			<Typography variant="subtitle2">
				Strafes are using engine glitch to turn mid-air and gain speed. During a jump player can turn mid-air, return
				etc. It can be used by simultaneous turning left and right causing acceleration in specific direction. While
				turning left you need to hold A key and turn the mouse to the left, for turning right you need to hold D key
				and turn the mouse to the right. Easy peasy.
			</Typography>
			<Typography variant="h6" component="h3">
				<Box my={3}>General rules of speedrun</Box>
			</Typography>
			<Typography variant="subtitle2">
				In speedrun player begins in the start zone. Time is counted from the moment you leave spawn to the end zone.
				The main rule is to run from point A to point B.
			</Typography>
			<Typography variant="h6" component="h3">
				<Box my={3}>Useful tricks and techniques:</Box>
			</Typography>
			<Typography variant="subtitle2">
				- figure eights (one-pointed, two-pointed, etc.) - technique invented by soQl, consists of smooth figure
				eights. It makes you gain some good speed. <br />
				- hook - technique which consists of making fast and certain move which will allow you to bounce from block
				which is closer than you would think.
			</Typography>
			<Typography variant="h6" component="h3">
				<Box my={3}>How does it work?</Box>
			</Typography>
			<Typography variant="subtitle2">
				Plugin counts time from the moment you leave spawn to the end zone. When you finish the map, plugin saves the
				data to the database and compares it to other players' times. It adds the label with your nickname and time to
				the ranking that is shown in !wr. When you fail, you're gonna be teleported into start zone and your time is
				gonna be reset - you need to complete the map again.
			</Typography>
			<Typography variant="subtitle2">
				<Box my={3}>
					You need to remember, that this modification is not like bhop, surf or codmod, but something different. That's
					true competition - trying to be better than others.
				</Box>
			</Typography>
			<Typography variant="h5" component="h3" align="center">
				<Box my={2}>Have fun!</Box>
			</Typography>
		</Container>
    );
  }
}

export default WhatIsSpeedrun;