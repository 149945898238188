import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
});


function createData(item, id) {
	let name = item.match(/auto-(\d{8})-(\d{6})(-workshop-\d*)?-(.*)\.dem.bz2/);
	let date = `${name[1].substring(0, 4)}-${name[1].substring(4, 6)}-${name[1].substring(6, 8)}`;
	let time = `${name[2].substring(0, 2)}:${name[2].substring(2, 4)}:${name[2].substring(4, 6)}`;
	// console.log(item, name);
	return { date, time, map: name[4], link: '../demos/' + name[0] };
}



	

const DemoItem = ({ data }) => {
	const classes = useStyles();
	
	const rows = Object.values(data).map((item, index) => {
		return createData(item, index);
	});
	
	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>ID</TableCell>
						<TableCell>Date</TableCell>
						<TableCell>Time</TableCell>
						<TableCell>Map</TableCell>
						<TableCell align="right">Download</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row, id) => (
						<TableRow key={id}>
							<TableCell>{id + 1}</TableCell>
							<TableCell component="th" scope="row">{row.date}</TableCell>
							<TableCell component="th" scope="row">{row.time}</TableCell>
							<TableCell component="th" scope="row">{row.map}</TableCell>
							<TableCell align="right">
								<Link href={row.link} color="secondary" underline="none">Download</Link>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default DemoItem;
